
import { BSkeletonImg } from 'bootstrap-vue'
export default {
  components: {
    'b-skeleton-img': BSkeletonImg,
  },
  data () {
    return {
      slide: 0,
      sliding: false,
      banners: []
    }
  },
  async fetch() {
    const banners = await this.$axios.get('banners')
    this.banners = banners.data.banner_slide
  },
//   async mounted () {
//     const banners = await this.$axios.get('banners')
//     this.banners = banners.data.banner_slide
//   },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    clickOnBanner(index, bannerUrl, videoUrl) {
      const banner = this.banners[index];
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "promoClicked",
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: "",
                name: String(banner.banner_path).replace(
                  /banners\/|\.jpg|\.png|\.gif/gi,
                  ""
                ),
                position: index + 1,
              },
            ],
          },
        },
      });
      if (videoUrl) {
        this.videoUrlBanner = videoUrl;
        this.$bvModal.show("modal-video-banner");
      } else if (bannerUrl) {
        window.location = bannerUrl;
      }
    },
    async showModal (banner) {
      if(banner.banner_position === 'banner_slide4') {
        await this.$firebaseBannerPlugin()
        window.location.href = "https://bit.ly/3ZxXeFz"
        // window.location.href = "https://line.me/R/ti/p/@gwc6379j?oat__id=1303339"
      }
    }
  }
}
