
import { BSkeletonImg, BSkeleton } from 'bootstrap-vue'
export default {
  components: {
    'b-skeleton-img': BSkeletonImg,
    'b-skeleton': BSkeleton
  },
  data () {
    return {
      sampleProductCategories: []
    }
  },
  async fetch() {
    const sampleProductCategories = await this.$axios.get('sample_product_categories')
    this.sampleProductCategories = sampleProductCategories.data.data
  },
  methods: {
  }
}
