import { render, staticRenderFns } from "./LoginViaOtp.vue?vue&type=template&id=12f5f046&scoped=true"
import script from "./LoginViaOtp.vue?vue&type=script&lang=js"
export * from "./LoginViaOtp.vue?vue&type=script&lang=js"
import style0 from "./LoginViaOtp.vue?vue&type=style&index=0&id=12f5f046&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12f5f046",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Consent: require('/app/components/Consent.vue').default})
